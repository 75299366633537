.App {
  text-align: left;
}

html,
body {
  overflow-x: hidden;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 400 !important;
  background-color: #f0f2f5 !important;
  overflow-x: visible;
}

.cursor-point {
  cursor: pointer;
}

.line-height-15 {
  line-height: 15px;
}

.text-12 {
  font-size: 12px !important;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
@page {
  size: A4 portrait;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-print-color-adjust: exact;
}

.bottom__button {
  width: 100% !important;
  text-align: right !important;
}

.color-dark-orange {
  color: darkorange !important;
}

.color-dark-blue {
  color: #069 !important;
}
.whiteSpace {
  white-space: pre-wrap !important;
}

.Back-arrow-blue {
  color: #39c !important;
}

.Back-arrow-blue:hover {
  color: #069 !important;
}

.radius-5 {
  border-radius: 5px;
}

.textDecoration {
  text-decoration: none !important;
}

.normal-casing {
  text-transform: none !important;
}

.text-bold {
  font-weight: bold !important;
}

.color-yellow {
  color: #ffff00;
}

.cursor-pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.twitter-share-button-karamale {
  width: 100% !important;
}

.color-skyblue {
  color: #79b9d3;
}

.twitter-share-btn {
  background-color: #17a2b8;
}

.twitter-share-btn:hover {
  background-color: #007bff;
  cursor: pointer;
}

.linkedin-share-btn {
  background-color: rgb(10, 102, 194, 0.7);
}

.linkedin-share-btn:hover {
  background-color: rgb(10, 102, 194);
  cursor: pointer;
}

.twitter-share-btn,
.whatsapp-share-btn,
.linkedin-share-btn {
  padding: 5px 6px 5px 6px;
  /* margin-left: -9px; */
  /* margin-top: -10px !important; */
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  font-weight: bold;
}

.whatsapp-share-btn {
  background: #66b266;
}

.whatsapp-share-btn:hover {
  background: #4ca64c;
  cursor: pointer;
}

.border-bottom-color {
  border-bottom: thin solid #ececec !important;
}

.affiliate-header-text {
  font-weight: 700;
  text-transform: none;
  padding: 0px !important;
  /* padding-left: 10px !important; */
  margin-top: 15px;
  color: #4d4d4f;
  font-size: 20px;
}

.border-hidden {
  border: hidden !important;
}
.background-color-white {
  background: #fff !important;
}
.float-right-mobile {
  float: right !important;
}
.blocks-3 {
  /* border: thin solid grey; */
  padding: 10px;
  text-align: center;
}

.become-an-affiliate-btn {
  width: 100px;
  color: #fff;
  background: #329932;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  height: 2.5em;
  font-weight: 700;
}

.become-an-affiliate-btn:hover {
  background: green;
  cursor: pointer;
}

.karamale-banner-bottom {
  height: 300px;
  background-image: url("https://karamale.com/apps/kscanner/icons/karamalebottomevent.jpg");
  background-position: center;
  border-radius: 5px;
  background-size: cover;
}

.karamale-banner {
  height: 300px;
  background-image: url("https://karamale.com/apps/kscanner/icons/karamaleevent.jpg");
  background-position: top center;
  border-radius: 5px;
}

.affiliate-banner-bottom {
  height: 300px;
  background-image: url("https://karamale.com/apps/kscanner/icons/success-bottom.jpg");
  background-position: center;
  border-radius: 5px;
  background-size: cover;
}

.affiliate-banner {
  height: 300px;
  background-image: url("https://karamale.com/apps/kscanner/icons/success.jpeg");
  background-position: top center;
  border-radius: 5px;
}

.color-semi-black {
  color: #303338;
}

.color-orange {
  color: #e59400;
}

.font-size-13 {
  font-size: 13px;
}

.url-holder {
  /* background: silver; */
  padding: 10px;
  border-radius: 5px;
  border: thin solid #dadde1;
}

.color-white {
  color: #fff;
}

.font-size-12 {
  font-size: 12px;
}

.text-input-bg-color-white {
  background: #fff !important;
}

.border-1 {
  border: thin solid #f0f2f5 !important;
  border-radius: 7px !important;
}

.notification-switch {
  position: absolute;
  left: 20px !important;
  bottom: 100px;
  z-index: 100;
}

span,
label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  /* margin-bottom: 0px !important; */
}

textarea,
input {
  color: #000 !important;
}

.font-size-20 {
  font-size: 20px !important;
  font-weight: 400 !important;
}
.width-200 {
  width: 200px !important;
}

.width-150 {
  width: 150px !important;
}

.preview-text {
  max-height: 200px;
  overflow-x: auto;
  display: flex;
}

.hover-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.color-light-grey {
  color: #dcdcdc;
}

.hide-file-input-type {
  display: none;
}

.color-green {
  color: #329932;
}

.color-black {
  color: #000 !important;
}

.countColor-error {
  color: red;
}
.countColor {
  color: #000;
}

.btn-border-color-grey {
  border: thin solid #ececec !important;
}

.btn-radius-3 {
  border-radius: 3px !important;
}
.font-size-15 {
  font-size: 15px !important;
}

.with-100 {
  width: 100px !important;
}

.arrow-back {
  margin-right: 7px;
  float: left;
  height: 40px;
  width: 40px;
  padding: 5px;
  text-align: center;
  background: #fff;
  border-radius: 100px;
}

.arrow-back:hover {
  background: #f0f2f5;
  cursor: pointer;
}

.close-covid-info {
  color: #fff;
  font-size: 15px !important;
}

.close-covid-info:hover {
  cursor: pointer;
  text-decoration: underline;
}

.covid-link {
  color: #fff;
}

.covid-link:hover {
  color: #fff !important;
}

.covid-info-box {
  margin-top: 10px;
  background: #006699;
  padding: 15px 15px 10px 15px;
  text-align: center;
  color: #fff;
}

.link-color {
  color: #39c !important;
}

.font-normal {
  font-weight: 400 !important;
  text-transform: none !important;
}
.new-event-btn {
  border: thin solid #7fbf7f;
  background: #7fbf7f;
  color: #fff;
  text-align: center;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  margin-top: -5px;
}

.new-event-btn:hover {
  background: #66b266;
  color: #fff;
  cursor: pointer;
}
.color-red {
  color: red;
}
.cell-relative {
  position: relative;
}
.sub-results-tranfer {
  position: absolute;
  max-width: 350px;
  background: #fff;
  font-size: 14px;
  right: 100%;
  z-index: 1000;
  box-shadow: silver 1px 1px 3px;
  padding: 10px;
  border-radius: 10px;
  top: 0px;

  /* border: thin solid red; */
}
.search-new-event::placeholder {
  font-size: 12px !important;
}
.search-new-event {
  height: 2em;
  border-radius: 100px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 12px !important;
  color: #000;
  background: #fff;
  border: thin solid #39c;
}

.color-blue {
  color: #39c !important;
}

.color-blue:hover {
  color: #069;
  cursor: pointer;
  text-decoration: underline;
}

.fontsize-12 {
  font-size: 12px;
}

.scan-title {
  color: #4ca64c !important;
}

.desktop-buttons-purchase-options {
  width: 100%;
}
.ticket-btn-container {
  /* border: thin solid red; */
  width: 100%;
  text-align: center;
  color: #333;
  display: none;
}

.provinceName {
  text-transform: capitalize;
}

.new-campaign {
  color: #3399cc;
}

.new-campaign:hover {
  color: #006699;
  cursor: pointer;
  text-decoration: underline;
}

.td-bottom-line {
  border: 0.5px solid silver !important;
}

.td-bottom-line td {
  border-bottom: 0.5px solid silver !important;
}

.td-bottom-line:hover {
  background: #fff !important;
}

.vertical-scroll {
  /* background-color: #333; */
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

.button-select {
  background: #3399cc !important;
  color: #fff !important;
  border-radius: 100px !important;
}

.listcustomers-header {
  width: 100% !important;
  max-height: 350px !important;
  overflow-y: auto;
}

.listcustomers,
.select-event,
.listplatforms {
  width: 300px !important;
  position: absolute;
  z-index: 100;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  border: thin solid #f3f4f4;
  /* margin-top: -30px; */
}

.listplatforms {
  right: 569px !important;
  width: 500px !important;
}

.listcustomers {
  right: 297px !important;
}
.select-event {
  right: 40px;
}

.refreshing-text {
  color: silver;
}
.refresh-icon {
  margin-right: 15px;
  padding: 8px 10px 5px 10px;
  border-radius: 100px;
  width: 42px;
  height: 42px;
  text-align: center;
  background: #f3f4f4;
  color: #333;
}

.refresh-icon:hover {
  background: #dcdcdc;
  cursor: pointer;
}
.button-disabled {
  color: silver !important;
  background: #f3f4f4 !important;
  cursor: not-allowed !important;
}

.new-campaign-button-active {
  background: #3399cc;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  color: #fff;
  /* font-weight: bold !important; */
  /* width: 150px; */
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}

.new-campaign-button-active:hover {
  background: #006699;
}

.new-campaign-button {
  background: #f3f4f4;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  color: #333;
  /* font-weight: bold !important; */
  text-align: center;
  margin-right: 10px;
}

.new-campaign-button:hover {
  background: #dcdcdc;
  cursor: pointer;
}

.widgets {
  /* margin-right: 10px; */
  /* margin-top: 10px; */
  cursor: pointer;
}

.campaigncounts {
  color: #fff;
  background: #66b266;
  margin-left: 5px;
  font-size: 10px;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
}

.campaignmanager-link {
  padding-top: 10px;
  padding-left: 15px;
  margin-left: 7px;
  /* margin-top: 5px; */
}

.campaign-link {
  color: #333;
  font-weight: 600;
}
.campaign-link:hover {
  color: #3399cc;
  text-decoration: none;
}

.login-screen-container {
  height: 100vh;
  /* width: 100%; */
}

.login-logo img {
  max-width: 230px;
}

.login-h2 {
  padding-right: 70px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  color: #333;
  font-size: 25px !important;
}

.login-logo {
  text-align: left;
}

.login-container-adjust {
  margin-bottom: 60px;
}

.flex {
  display: flex;
}
.fontNormal {
  font-weight: 400;
}

.graph-heading {
  font-weight: 700;
  color: rgb(77, 77, 79);
}

.graph-titles {
  font-weight: 700;
  color: rgb(77, 77, 79);
  font-size: 20px;
}

.modalBGcolor {
  background: #fff;
  border-radius: 10px;
}

.add-booster {
  text-transform: none;
}
.add-booster:hover {
  color: #333;
  cursor: pointer;
}

.padding-added {
  padding-bottom: 40px !important;
}

.add-event {
  position: fixed;
  color: #fff !important;
  width: 155px;
  padding: 10px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 0px;
  text-align: center;
  background: #ff3232;
  bottom: 50px;
  right: 60px;
  border-radius: 100px;
  z-index: 1000;
  box-shadow: #9e9e9e 0px 3px 10px;
}

.add-event:hover {
  background: red;
  cursor: pointer !important;
}

.resume-btn {
  background: #3399cc;
  color: #fff;
  text-align: center;
  float: right;
  border-radius: 100px;
  padding: 10px;
  width: 100px;
  font-size: 15px;
  font-weight: bold;
}

.resume-btn:hover {
  background: #006699;
  cursor: pointer;
}

.box-upload-dis {
  border: hidden;
  color: silver;
  width: 430px;
  height: 430px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-upload {
  border: 2px dashed silver;
  color: silver;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-upload:hover {
  color: #4d4d4f;
  border: hidden;
  cursor: pointer;
  background-color: #f3f3f4;
}

.category-name-heading {
  font-weight: 700;
  text-transform: none;
  padding: 0px !important;
  padding-left: 10px !important;
  margin-top: 15px;
  color: #4d4d4f;
  font-size: 20px;
}

.cats-more {
  font-size: 14px !important;
  border-radius: 10px !important;
}

.name-events {
  display: none;
}

.more-cat {
  color: #3399cc !important;
}

div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px;
}

.break {
  display: none;
}

.desktop-width {
  width: 100%;
}

.blanket {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0%;
  bottom: 0%;
  background: rgb(0, 0, 0, 0.7);
  z-index: 1000;
}

.noteB {
  font-size: 14px;
  font-weight: 400;
}
.click-here {
  color: #3399cc;
}
.buy-text {
  font-size: 12px;
}

.normal-casing {
  text-transform: none;
}

.lower-casing {
  text-transform: lowercase;
}

input {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px;
  color: #000;
}
.note-line {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 400 !important;
  color: #8d949e;
  text-transform: none;
  font-size: 13px;
}

.edit-input {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 400;
  font-size: 15px !important;
  color: #000;
  border-radius: 5px;
  margin-top: 10px;
  background: #f0f2f5;
}

.cancel-btn {
  border: thin solid #ff3232;
  color: #ff3232;
  text-align: center;
  width: 100px;
  padding: 8px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 10px;
  font-size: 15px;
  background: #fff;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.cancel-btn:hover {
  border: thin solid #3399cc;
  color: #3399cc;
  cursor: pointer;
}

.save-btn {
  border: thin solid #ff3232;
  color: #fff;
  text-align: center;
  width: 100px;
  padding: 8px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 10px;
  font-size: 15px;
  background: #ff3232;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.save-btn:hover {
  background: red;
  cursor: pointer;
}

.edit-btn {
  /* border: thin solid #ff3232; */
  color: #8d949e;
  text-align: center;
  /* width: 100px; */
  padding: 8px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 10px;
  font-size: 15px;
  /* background: #ff3232; */
}

.edit-btn:hover {
  color: red;
}

.text-size {
  font-weight: 400;
  font-size: 15px;
}

.small-details {
  color: #8d949e !important;
  font-weight: 400;
}

.details-body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 13px;
}

.details-body span {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px;
}

.admin-cate {
  color: #8d949e;
  font-size: 12px;
  font-weight: normal;
  text-transform: "none";
  cursor: pointer;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
.note-info {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px;
  color: #8d949e;
  font-weight: 400;
  cursor: default;
}
.back-arrow {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px;
  color: #39c;
}

.back-arrow:hover {
  color: #069;
  cursor: pointer;
}

.successfully-out {
  color: #333 !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  margin-top: 0px !important;
}
.letter-head {
  color: #000;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 13px;
  text-align: center;
}
.link-btn {
  text-decoration: none !important;
  color: #000;
}
.menu-links {
  cursor: pointer !important;
  color: #000 !important;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 10px 5px 18px 3px;
  /* padding: 17px; */
  /* padding-left: 0px;
  padding-right: 0px; */
}
.menu-links .item-names:hover {
  cursor: pointer !important;
}
.log-in-btn {
  background: #3399cc;
  padding: 10px;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  border-radius: 15px;
  font-weight: bold;
}

.log-in-btn:hover {
  background: #006699;
  cursor: pointer;
}
.new-account-btn {
  background: #ff4c4c;
  padding: 10px;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  border-radius: 15px;
  font-weight: bold;
}

.new-account-btn:hover {
  background: red;
  cursor: pointer;
}

.businessname {
  font-size: 11px;
  text-transform: none;
  font-weight: 400;
  color: rgb(83, 100, 113);
}

.event-poster {
  border-radius: 10px;
}

.search-ticket-input {
  /* border-radius: 10px; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px !important;
  color: #000;
  background: #f0f2f5;
  /* border: thin solid #dcdcdc; */
}
.item-user:hover,
.custername-profile:hover,
.profile-icon:hover {
  background: #f0f2f5;
  border-radius: 10px;
  cursor: pointer;
  /* color: #fff; */
}
.item-user {
  padding: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.item-top-header {
  background: #fff;
  display: flex;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.disabledBar {
  background: #f0f2f5;
  display: flex;
  width: 95%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 10px;
  /* border: thin solid deeppink; */
}

.hide-border {
  border: none;
}
table tr td {
  border: 0.5px solid silver;
  border-bottom: hidden;
  padding: 10px 10px 10px 10px;
}

table {
  overflow: scroll;
}
table tr {
  background: #fff;
}

table tr:hover {
  background: #f0f2f5;
  border-radius: 10px;
}

.item-select {
  padding: 10px 0px 0px 0px !important;
}
.item-select,
.item-user,
.item-list {
  background: #fff;
  display: flex;
  width: 95%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  /* height: 0px; */
  /* transition: linear 0.2s; */
}

.item-select:hover {
  background: #f0f2f5;
  border-radius: 10px;
  cursor: pointer;
}

.item-select,
.reprint-select {
  margin-bottom: 0px;
  /* padding: 10px; */
  padding-left: 0px;
  padding-right: 0px;
}

.reprint-select {
  background: #39c;
  display: flex;
  width: 95%;
  /* padding: 10px; */
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px;
  /* height: 0px; */
  /* transition: linear 0.2s; */
}

.reprint-select:hover {
  background: #069;
}

.item-select-active {
  background: #f0f2f5;
  display: flex;
  width: 95%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  /* padding: 10px; */
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 10px;
  cursor: pointer;
}

.item-names-disabled {
  font-size: 15px;
  margin-top: 5px;
  color: #dcdcdc;
}
.item-names {
  font-size: 16px;
  font-weight: 600 !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.h-divider {
  border-bottom: thin solid #f0f2f5;
  width: 90%;
  margin-top: 12px;
  margin-bottom: 12px;
}
.custername-profile,
.ticket-title {
  font-size: 13px;
  margin-top: 5px;
  font-weight: 700;
  /* text-transform: uppercase !important; */
}

.BoxDashboardContainer {
  /* border: thin solid #f3f4f4; */
  box-shadow: silver 1px 2px 3px;
  padding: 20px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  /* padding-bottom: 20px; */
  /* margin-top: -20px; */
}

.success-login,
.error-login {
  width: 300px;
  padding: 20px;
  font-size: 20px;
  color: #fff;
  text-align: center !important;
  position: fixed;
  bottom: 0px;
  z-index: 120000;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  left: 40%;
  bottom: 60px;
  border-radius: 15px;
  font-weight: bold;
}
.error-login {
  background: rgb(128, 0, 0, 0.8);
}

.success-login {
  background: rgb(51, 153, 204, 0.8);
}

.arrow-pointer {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: arrow-pointer;
  animation-timing-function: linear;
}

@keyframes arrow-pointer {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.choose-account {
  font-size: 13.5px;
  font-weight: 400;
}

.partner {
  width: 150px;
  height: 150px;
  border: 2px solid #ffae19;
  color: #ffae19;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: linear 0.2s;
}

.partner:hover {
  cursor: pointer;
  background: #ffae19;
  border: 2px solid #ffae19;
  color: #fff;
  transform: scale(1.1);
}

.business {
  width: 150px;
  height: 150px;
  border: 2px solid #3399cc;
  color: #3399cc;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: linear 0.2s;
}

.business:hover {
  cursor: pointer;
  background: #3399cc;
  border: 2px solid #3399cc;
  color: #fff;
  transform: scale(1.1);
}

.customer {
  width: 150px;
  height: 150px;
  border: 2px solid #66b266;
  color: #66b266;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: linear 0.2s;
}

.customer:hover {
  cursor: pointer;
  background: #66b266;
  border: 2px solid #66b266;
  color: #fff;
  transform: scale(1.1);
}

.my-tickets-btn {
  padding: 10px;
  border-radius: 5px;
  font-size: 13.5px;
  color: #fff;
  border: thin solid #006699;
  margin-top: 20px #3399cc;
  width: 100px;
  background: #006699;
  text-decoration: none !important;
}

.my-tickets-btn:hover {
  background: red;
  color: #fff;
  border: thin solid red;
}

.search-value {
  color: darkorange;
  font-size: 13.5px;
  font-weight: 400 !important;
}
.no-match-found {
  font-size: 13.5px;
  color: #333;
}

.yep-event {
  color: #66b266;
  font-size: 13px;
  width: 100%;
  text-align: center;
}

.warning-event {
  color: darkorange;
  font-size: 13px;
  width: 100%;
  text-align: center;
}

.heading-admin {
  font-weight: bold !important;
  font-size: 13px;
}

.ticket-price {
  float: right;
  font-size: 13px;
  font-weight: 400;
}

.ticket-btn-disabled {
  border: thin solid #f3f4f4;
  background: #f3f4f4;
  color: silver;
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
  width: 100%;
  text-decoration: none !important;
  margin-bottom: 15px;
}

.ticket-btn {
  border: thin solid #333;
  background: #333;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
  width: 100%;
  text-decoration: none !important;
  margin-bottom: 15px;
}

.ticket-btn:hover {
  border: thin solid red;
  background: red;
  color: #fff;
  text-decoration: none !important;
  cursor: pointer;
}

.tickets {
  background: #fff;
  position: fixed;
  right: 130px;
  bottom: 85px;
  z-index: 1111;
  width: 300px;
  padding: 15px;
  border-radius: 20px 20px 0px 0px;
  border: thin solid silver;
  border-bottom: hidden;
  /* box-shadow: #9e9e9e 1px -1px 5px; */
}
.event-name {
  width: 100%;
  padding: 20px;
  border-top: thin solid silver;
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 1100;
}

.left-navigation {
  position: relative !important;
}

.category-name {
  font-size: 13px;
  color: grey;
  font-weight: 400;
  text-transform: capitalize;
}

.provinces {
  text-transform: capitalize;
  color: #000;
  background: #fff;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}

.provinces:hover {
  background: #f0f2f5;
  /* color: #fff; */
  cursor: pointer;
  border-radius: 5px;
}

.heading {
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  background-color: rgb(255, 0, 0, 0.8);
  padding: 8px;
  padding-left: 17px;
  padding-right: 17px;
  margin-bottom: 6px;
  border-radius: 5px 5px 0px 0px;
}

.heading-container {
  background: #fff;
  width: 100%;
  padding: 0px;
  border-radius: 5px;
}

.h4-heading {
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 250px;
  text-align: center;
}

.h4-bgcolor-green {
  background: #329932;
}
.color-grey-light {
  color: #f0f2f5 !important;
}
.h4-bgcolor-red {
  background: #ff4c4c;
}
.color-grey {
  color: grey !important;
}

.text-transform {
  text-transform: none;
}

::placeholder {
  color: #606770;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400 !important;
  overflow-x: hidden !important;
  /* padding-right: 0px !important;
  padding-left: 25px !important; */
}

.budget-calculation div {
  font-weight: 400;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.budget-calculation b,
.container b {
  font-weight: 900;
}

.feedback-elements input {
  width: 15px !important;
  height: 15px !important;
  margin-bottom: 15px;
  margin-right: 10px;
}

.msg-success {
  color: green;
}

#file {
  font-size: 14px !important;
}

.max-message {
  color: red;
  font-size: 15px;
  text-transform: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
.hand-cursor {
  cursor: pointer;
}
.textGrey {
  color: #333;
  font-size: 30px;
}

.search-arrow:hover {
  color: red;
}

div.open {
  display: none;
}
.links-width {
  width: 100%;
}
.default-padding {
  padding-left: 0px !important;
  padding-right: 10px !important;
}
.search-results-inner {
  display: flex;
}

.search-container {
  background: #f0f2f5;
  font-size: 16px;
  border-radius: 100px;
  color: grey;
  width: 40px !important;
  height: 40px !important;
  text-align: center;
  /* float: left; */
  padding: 5px;
  /* padding-top: 4px; */
}

.results-container {
  padding-left: 20px;
  font-size: 14px;
  font-weight: 500;
}

.search-results-inner {
  padding: 12px;
  color: #333;
  border-radius: 10px;

  text-transform: capitalize;
  width: 100% !important;
  font-weight: 400;
}

.search-results-inner:hover {
  background: #f0f2f5;
  cursor: pointer;
  color: #000;
}

.resu {
  color: green !important;
}
.search-results {
  position: absolute !important;
  font-size: 15px;
  top: 130% !important;
  left: 0%;
  padding-left: 10px !important;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  box-shadow: #bec3c9 -1px 7px 10px;
  width: 71% !important;
  /* display: none; */
}
.response {
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px !important;
  color: red;
  margin-top: -21px !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(70vh - 60px);
  scroll-behavior: smooth;
  border-top: hidden;
}

.vertical-scroll::-webkit-scrollbar,
.preview-text::-webkit-scrollbar,
.vertical-scroll-mobile::-webkit-scrollbar {
  width: 5px !important;
}

.response::-webkit-scrollbar,
textarea::-webkit-scrollbar,
.select-event::-webkit-scrollbar,
.listcustomers-header::-webkit-scrollbar {
  width: 10px;
}

.response::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
.select-event::-webkit-scrollbar-thumb,
.listcustomers-header::-webkit-scrollbar-thumb,
.vertical-scroll::-webkit-scrollbar-thumb,
.preview-text::-webkit-scrollbar-thumb,
.vertical-scroll-mobile::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 10px;
}

.response::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover,
.select-event::-webkit-scrollbar-thumb:hover,
.listcustomers-header::-webkit-scrollbar-thumb:hover,
.vertical-scroll::-webkit-scrollbar-thumb:hover,
.vertical-scroll-mobile.response::-webkit-scrollbar-thumb:hover {
  background: silver;
  border-radius: 10px;
}

.response::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
.select-event::-webkit-scrollbar-track,
.listcustomers-header::-webkit-scrollbar-track,
.vertical-scroll::-webkit-scrollbar-track,
.vertical-scroll-mobile::-webkit-scrollbar-track {
  background: #f3f3f4;
}

.adjust-margin {
  margin-top: -170px;
}

.shield {
  background: rgb(255, 255, 255, 0.9);
}

.background-img {
  background-image: url("/favicon.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0vh;
  background-attachment: fixed;
  height: 100vh;
}
.cancelled-message,
.sub-text-cancelled-message,
.sub-text-success-message,
.success-message {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400 !important;
}

.cancelled-message,
.sub-text-cancelled-message {
  color: red;
}

.sub-text-success-message,
.success-message {
  color: #66b266;
}

.sub-text-success-message,
.sub-text-cancelled-message {
  font-size: 20px;
}

.success-message,
.cancelled-message {
  font-size: 30px;
}
.success-response {
  /* border: thin solid red; */
  height: 90.5vh;
}

.text-15 {
  font-size: 15px;
  color: grey;
}

.totalAmount {
  width: 33px;
  float: right !important;
  background: transparent;
  padding: 0px;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: -11px;
  text-align: center;
}
.admissionInput {
  text-align: center;
  width: 100% !important;
  border-radius: 5px;
}

.add {
  background: #66b266;
  /* height: 2.5rem; */
  color: #fff;
  font-weight: bolder;
}

.add:hover {
  background: #329932;
}

.minus {
  background: #ff6666;
  color: #fff;
  font-weight: bolder;
}

.minus:hover {
  background: #ff4c4c;
}

.minus,
.add {
  width: 100%;
  border-radius: 5px;
  border: hidden;
  font-size: 20px;
  height: 2.7rem;
  outline: none !important;
}

.terms {
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.checkbox {
  width: 15px;
}

.form-components > input {
  margin-bottom: 15px;
  font-weight: lighter !important;
  border-radius: 5px;
  /* background: #fff; */
  border: thin solid #dcdcdc;
}
.eft-payment-method {
  background: #f3f3f3;
  padding: 5px;
  color: #333;
  border-radius: 5px;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
}

.eft-payment-method:hover {
  background: #dcdcdc;
  cursor: pointer;
}

.card-payment-method {
  width: 100%;
  background: #ff4c4c;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.card-payment-method:hover {
  background: #ff1919;
  cursor: pointer;
}

.upperCasedText {
  text-transform: uppercase;
}

.capitalizedText {
  text-transform: capitalize;
}

.text-red {
  color: red;
}

.normalCase {
  text-transform: none;
}

div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.spacer {
  padding-top: 20px;
  height: 50px;
}

.more-icons {
  font-size: 27px !important;
  color: #000;
  margin-top: 10px;
  background: #f0f2f5;
  /* border: thin solid #333; */
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 100px;
  margin-right: 20px !important;
  cursor: pointer;
}
.btn-container-padding {
  padding-left: 10px;
}

.ticket-title {
  width: 100%;
  padding: 0px 15px 0px 15px;
  /* border-bottom: thin solid #dcdcdc; */
  color: #333;
  font-weight: bold;
  /* text-transform: capitalize; */
  border-radius: 5px 5px 0px 0px;
  /* padding-bottom: 10px; */
  /* margin-bottom: 10px; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
.margins {
  margin-bottom: -10px;
  margin-top: 40px;
  /* padding: 10px !important; */
}

.btn-container {
  width: 100%;
  text-transform: capitalize;
  font-size: 13px;
  margin-bottom: -30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 10px;
}

.activeLink {
  color: #3399cc !important;
}

.mobile-position {
  text-align: left !important;
}

.padding {
  padding-left: 10px;
  padding-right: 10px;
}

.text-size-small {
  font-size: 13px;
}

.greenText {
  color: green;
}

.redText {
  color: red;
}

.defineColor {
  float: left;
}

.rank {
  background: rgb(255, 0, 0, 0.7);
  color: #fff;
  position: absolute;
  width: 40px;
  top: 0.3%;
  right: 4.9%;
  text-align: center;
  padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  border-radius: 0px 4px 0px 0px;
}

.case-sentence {
  text-transform: capitalize;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.textMedium {
  font-weight: 400 !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.color-red {
  color: red;
}

.textLight {
  font-weight: 400 !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px;
}

.label-shadow-ranking {
  background: rgb(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 0px 0px 5px 5px !important;
  padding: 10px;
  height: 35%;
  font-size: 14px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: lighter !important;
}

.define-ranking-box {
  background-size: cover;
  height: 250px;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.greyColor {
  color: grey;
}

span {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
.nav-links {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px !important;
  font-weight: 400;
}

h4 {
  font-weight: bolder !important;
  font-family: Arial, Helvetica, sans-serif;
}
.backArrow {
  font-size: 17px;
  color: red;
  cursor: pointer;
}
.event-title {
  text-transform: capitalize;
  font-size: 17px;
  color: #000;
  font-weight: 700 !important;
}

.backbtn {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}

.btnDisabled {
  padding: 10px;
  margin-bottom: 15px !important;
  font-weight: 400 !important;
  color: #bec3c9;
  background: #f0f2f5;
  border: thin solid #f0f2f5;
  border-radius: 7px !important;
  cursor: not-allowed !important;
  outline: none !important;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.karamale-btn-round,
.label-btn-round,
.btnDisabled {
  border-radius: 100px;
}

.karamale-btn-square,
.label-btn-square {
  border-radius: 7px;
}

.karamale-btn,
.label-btn {
  padding: 10px;
  border: thin solid red;
  background: red;
  margin-bottom: 15px;
  color: #fff;
  font-weight: 400;
  outline: none !important;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.karamale-btn:hover,
.label-btn:hover {
  background: #ff3232;
  color: #fff;
  border: thin solid #ff3232;
  cursor: pointer;
  transition: linear 0.2s;
}

.buy-btn {
  margin-top: 42px;
}

.greyText {
  color: grey;
  font-size: 13px;
  font-weight: 200;
}

.boldText {
  font-weight: 900;
}
.at {
  color: grey;
  font-weight: 400;
}

.time {
  color: #000;
  font-weight: 400;
  font-size: 13px;
  margin-top: 0px;
  text-transform: none;
}
.textWeight {
  font-weight: bold;
  color: #333;
  font-size: 13px;
  text-transform: capitalize;
}

.eventLinks {
  color: #333;
  text-decoration: none !important;
}

.eventLinks:hover {
  color: #000;
}
.nainer {
  background: #fff;
  padding: 0px;
  border-radius: 5px;
  border: thin solid #f3f4f4;
  /* box-shadow: 0 2px 2px 0 rgb(77 77 79 / 8%); */
  border: thin solid #dcdcdc;
  transition: linear 0.3s;
  height: 280px;
}

.nainer:hover {
  /* background: #ff1919; */
  box-shadow: grey 0px 3px 10px;
}

.content-detail {
  background: #fff;
  padding: 20px;
  border-radius: 20px !important;
}

.img-poster {
  border-radius: 20px;
}

.eventName {
  font-size: 18px;
  font-weight: 200;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: capitalize;
  color: grey;
  font-size: 13px;
  position: absolute;
  width: 89%;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 15px;
}
.imageContainer {
  /* border: thin solid #f3f4f4; */
  height: 250px;
  border-radius: 5px 5px 0px 0px;
  background-size: cover;
  /* box-shadow: grey 0px 3px 10px; */
}

.imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
}
.links {
  text-decoration: none !important;
  color: #333;
}

.text-footer {
  margin-top: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px;
  color: #000;
  /* margin-bottom: 20px; */
}

.create-account-btn {
  background: #3399cc;
  padding: 8.5px;
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  border-radius: 15px;
}

.create-account-btn:hover {
  background: #006699;
  cursor: pointer;
}

.horizontal-line {
  width: 100%;
  border-top: thin solid #f0f2f5;
  margin-bottom: 20px;
}

.view {
  /* background: #dadde1; */
  background: #f0f2f5;
  padding-top: 0px !important;

  /* height: 91.6vh; */
}

div {
  font-family: Arial, Helvetica, sans-serif;
}

.forgotPasswordText {
  margin-bottom: 15px;
  color: #3399cc;
  font-size: 13.5px;
}

.forgotPasswordText:hover {
  color: #006699;
  cursor: pointer;
  text-decoration: none !important;
}

.log-title {
  margin-bottom: 15px;
  font-weight: bolder;
  color: red;
  font-size: 45px;
  font-family: Arial, Helvetica, sans-serif;
}
.BoxDashboardContainer > button {
  width: 100%;
  height: 2.9rem;
  border-radius: 15px;
  background: #ff3232;
  color: #fff;
  border: hidden;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
  outline: none !important;
}

.BoxDashboardContainer > button:hover {
  background: #ff1919;
  cursor: pointer;
}

.BoxDashboardContainer > input,
.BoxDashboardContainer > select,
.BoxDashboardContainer > .PhoneInput {
  border: thin solid silver;
  width: 100%;
  height: 2.7rem;
  border-radius: 5px;
  padding: 10px;
  font-weight: 350;
  margin-bottom: 15px;
}

.just-container-inside,
.just-container-insid {
  /* height: calc(100vh - 50px); */
  width: 100%;
}
.just-container {
  height: calc(100vh - 100px);
}
.BoxContainer {
  border: thin solid #f3f4f4;
  box-shadow: silver 0px 3px 10px;
  padding: 15px;
  width: 400px;
  background: #fff;
  border-radius: 10px;
}

.event-tabs {
  /* color: #333; */
  text-decoration: none !important;
  color: #606770;
}

.header-tabs {
  padding: 10px;
  padding-top: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: 350;
  margin-top: 10px;
  border-radius: 5px;
  /* color: #333; */
  text-decoration: none !important;
  /* color: #606770; */
}

.header-tabs:hover {
  background: #f0f2f5;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none !important;
}

.header-tabs-active {
  /* background: #f0f2f5; */
  cursor: pointer;
  text-decoration: none !important;
  /* border-radius: 10px; */
  color: red;
}

.Header-wrapper {
  flex-direction: row;
  padding: 10px;
}

textarea {
  background: #f0f2f5;
  border-radius: 10px !important;
  border: hidden;
  width: 100%;
  height: 12rem;
  resize: none !important;
  /* padding: 5px; */
  padding: 15px;
  outline: none !important;
  color: grey;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 15px !important;
}

input {
  font-size: 17px !important;
  font-weight: 400 !important;
  color: grey;
  outline: none;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px !important;
  border: hidden;
  outline: none !important;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  background: #f3f3f4;
  height: 2.7rem;
}

.search-input {
  /* border: thin solid #f0f2f5; */
  background: #f0f2f5;
  border-radius: 100px;
  width: 250px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px !important;
  color: #000;
  /* padding-bottom: 7px !important; */
}

/* .search-input:hover {
  cursor: pointer;
} */

.header-app-logo {
  width: 44px;
  margin-right: 5px;
  margin-top: 1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
  padding: 0px 0px 7px 0px;
  border-bottom: thin solid #f3f4f4;
  /* box-shadow: #9e9e9e 2px 0px 4px 0px; */
  position: fixed;
  width: 100%;
  top: 0;
  /* height: 65px; */
  /* border: thin solid red; */
  z-index: 1000;
  /* margin-bottom: -60px */
}

@media screen and (max-width: 1006px) {
  .listcustomers,
  .select-event {
    right: 40px !important;
    border: thin solid #dcdcdc;
    width: 350px !important;
  }

  .listplatforms {
    right: 40px !important;
    border: thin solid #dcdcdc;
    width: 600px !important;
  }

  .ticket-btn {
    background: #fff;
    color: #3399cc;
    border-radius: 100px;
    border: thin solid #3399cc;
    text-align: center;
    margin-bottom: 5px;
  }
  .break {
    display: inline;
  }

  .desktop-buttons-purchase-options {
    display: none;
  }

  .ticket-btn-container {
    display: block;
    text-align: center !important;
  }
  .BoxDashboardContainer {
    box-shadow: none;
    /* width: 100%; */
    /* margin-top: -20px; */
  }

  .tickets-assigned {
    display: none;
  }

  .just-container-inside {
    height: calc(100vh - 0px);
  }
  .search-results {
    width: 50% !important;
  }
  .search-input {
    width: 80% !important;
  }
}

@media screen and (max-width: 856px) {
  .mobile-hiding {
    display: none;
  }
  .vertical-scroll-mobile {
    /* background-color: #333; */
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
  }
  .row-margin-bottom {
    margin-bottom: 20px;
  }
  .float-right-mobile {
    float: none !important;
  }
  .hand-shake-log-in {
    display: none;
  }
  .break-to-next-line {
    display: inline;
  }
  .pipe-holder {
    display: none;
  }
  .notification-switch {
    display: none;
  }
  .listcustomers,
  .select-event,
  .listplatforms {
    right: 30px !important;
    border: thin solid #dcdcdc;
    width: 320px !important;
  }
  .ticket-btn {
    background: #fff;
    color: #3399cc;
    border-radius: 100px;
    border: thin solid #3399cc;
    text-align: center;
    margin-bottom: 5px;
  }
  .break {
    display: inline;
  }

  .desktop-buttons-purchase-options {
    display: none;
  }

  .ticket-btn-container {
    display: block;
    text-align: center !important;
  }

  .left-navigation {
    display: none;
  }
  .padding-added {
    padding-bottom: 0px !important;
  }
  .search-results {
    width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .notification-switch {
    display: none;
  }

  .listcustomers,
  .select-event {
    right: 5px !important;
    width: 360px !important;
  }

  .listplatforms {
    right: 5px !important;
    border: thin solid #dcdcdc;
    width: 360px !important;
  }

  .desktop-buttons-purchase-options {
    display: none;
  }

  .ticket-btn-container {
    display: block;
  }
  .login-logo img {
    max-width: 200px;
  }

  .login-h2 {
    padding-right: 0px;
    font-size: 20px !important;
  }

  .login-logo {
    text-align: center;
  }
  .login-container-adjust {
    margin-bottom: 25px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .hide-title {
    display: none;
  }
  .add-event {
    position: fixed;
    color: #fff;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-right: 7px;
    bottom: 30px;
    right: 40px;
  }
  .category-name-heading {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .name-events {
    display: none;
    padding: 8px !important;
    padding-top: 0px !important;
    font-weight: 700;
    color: #4d4d4f;
    font-size: 20px;
  }
  .heading-container {
    height: 370px;
    border: thin solid #dcdcdc;
  }
  .province-pads {
    padding-right: 10px !important;
  }

  .category-pads {
    padding-left: 10px !important;
  }

  .response {
    max-height: calc(60vh - 60px);
  }
  .desktop-width {
    width: fit-content;
    padding-bottom: 50px;
  }
  .ticket-btn {
    background: #fff;
    color: #3399cc;
    border-radius: 100px;
    border: thin solid #3399cc;
    text-align: center;
    margin-bottom: 5px;
  }
  .break {
    display: inline;
  }

  .mobile-break {
    float: none !important;
    /* color: #333; */
  }

  .tickets {
    background: #fff;
    position: fixed;
    right: 0px;
    bottom: 80px;
    z-index: 1111;
    width: 100%;
    padding: 15px;
    padding-bottom: 0px;
    border-radius: 20px 20px 0px 0px;
    border: thin solid silver;
    border-bottom: hidden;
    margin-top: 400px;
  }

  .house-event {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .house-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .note-info {
    font-size: 14px;
  }
  .search-results {
    width: 100% !important;
  }

  .adjust-margin {
    margin-top: 0px;
  }
  .success-response {
    height: 70vh;
  }
  .background-img {
    background-image: unset;
  }
  .kr-text,
  .filter {
    display: none;
  }
  .mobile-position {
    text-align: center !important;
  }
  .arrowIcons,
  .mobile-top-links {
    display: none;
  }
  .padding {
    padding-left: 15px;
    padding-right: 27px;
  }
  /* .menu-row-desktop {
    display: none;
  } */

  .home-link {
    margin-left: 45px !important;
  }
  /* .Header-wrapper {
    display: none;
  } */

  .search-col-mobile,
  .more-col-mobile,
  .mobile-liner {
    display: inline-block !important;
  }

  .mobile-bottom-links {
    margin-bottom: 15px !important;
    display: none !important;
  }

  .mobile-top-links {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 575px) {
  .width-100 {
    width: 100% !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 991px) {
  .notification-switch {
    display: none;
  }
  .search-results {
    width: 60% !important;
  }
  .space-div {
    display: none !important;
    border: thin solid red;
  }
  .search-input {
    width: 90% !important;
  }

  .more-col-mobile {
    display: inline-block !important;
    /* margin-left: -20px !important; */
  }

  .header-tabs {
    display: none;
  }
}

.mobile-top-links {
  margin-bottom: 0px;
}
.mobile-bottom-links {
  margin-bottom: 0px;
  display: inline-block;
}
.search-col-mobile,
.more-col-mobile,
.mobile-liner {
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
