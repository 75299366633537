.close-x {
  position: fixed;
  top: 60px;
  left: 50px;
  z-index: 1550;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
}

.close-x:hover {
  color: silver;
}
