.slide-drawer {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1250;
  width: 60%;
  max-width: 300px;
  background: #fff;
  box-shadow: 1px 7px 2px #333;
  padding: 15px;
  transform: translateX(0%);
  transition: transform 0.5s ease-out;
}

.slide-drawer.open {
  transform: translateX(0);
}

.mobile-nav {
  padding: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
